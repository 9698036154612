import React from 'react';
import './App.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Header from './components/Header';
import About from "./components/About";
import Subscribe from "./components/Subscribe";
import Contact from "./components/Contact";
import Footer from './components/Footer';

const theme = createMuiTheme({
    palette: {
        text: {
            primary: '#fff',
            secondary: '#ccc'
        }
    }
});

function App() {
    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <Header/>
                <About/>
                <Contact/>
                <Footer/>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
