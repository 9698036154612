import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import designImage from "../assets/design-image.svg";
import deployImage from "../assets/deploy-image.svg";
import useImage from "../assets/use-image.svg";
import {Grid} from "@material-ui/core";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }
});

class About extends React.Component {
    state = {
        imgHeight: '400rem',
    };

    render() {
        return (
            <div id="about" className="App-about">
                <Grid container justify="center" spacing={16}>
                    <Grid item xs={12} lg={4}>
                        <br/>
                        <img src={designImage} alt="DESIGN" height={this.state.imgHeight}/>
                        <Typography component="h3" variant="h3" align="center" color="textPrimary">
                            DESIGN
                        </Typography>
                        <br/>
                        <Typography component="h6" variant="h6" align="center" color="textSecondary">
                            build APIs, Web Apps and Mobile Apps
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <br/>
                        <img src={deployImage} alt="DEPLOY" height={this.state.imgHeight}/>
                        <Typography component="h3" variant="h3" align="center" color="textPrimary">
                            DEPLOY
                        </Typography>
                        <br/>
                        <Typography component="h6" variant="h6" align="center" color="textSecondary">
                            deploy with one click to the cloud
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <br/>
                        <img src={useImage} alt="USE" height={this.state.imgHeight}/>
                        <Typography component="h3" variant="h3" align="center" color="textPrimary">
                            USE
                        </Typography>
                        <br/>
                        <Typography component="h6" variant="h6" align="center" color="textSecondary">
                            your apps are ready to be used
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(About);
