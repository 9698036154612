import axios from 'axios';

const contactEndpoint = 'http://api.appsmith.io/api/contacts';

export default class ContactApi {
    static contact(name, email, message) {
        return axios.post(contactEndpoint, {
            name: name,
            email: email,
            message: message
        });
    }
}
