import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ContactApi from "../util/ContactApi";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/es/Button";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }
});

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email: '', message: '', displaySuccessMessage: 'none'};
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleMessageChange(event) {
        this.setState({message: event.target.value});
    }

    handleSubmit() {
        if (!this.state.name || !this.state.email || !this.state.message) {
            return;
        }
        const handler = () => {
            this.setState({name: '', email: '', message: '', displaySuccessMessage: 'block'});
            setTimeout(() => {
                this.setState({displaySuccessMessage: 'none'});
            }, 3000);
        };
        ContactApi.contact(this.state.name, this.state.email, this.state.message)
            .then(() => handler())
            .catch(reason => {
                handler();
                console.error(reason);
            });
    }

    render() {
        return (
            <div id="contact" className="App-contact">
                <section>
                    <Typography component="h3" variant="h3" align="center" color="textPrimary">
                        CONTACT US
                    </Typography>
                    <br/>
                    <Typography component="h6" variant="h6" align="center" color="textSecondary">
                        send us your feedback or questions
                    </Typography>
                    <br/>
                    <form autoComplete="off">
                        <TextField
                            id="contact-name"
                            name="contact-name"
                            label="Name"
                            placeholder="Jon Doe"
                            margin="normal"
                            variant="outlined"
                            required={true}
                            fullWidth
                            value={this.state.name}
                            onChange={this.handleNameChange}
                        />
                        <TextField
                            type="email"
                            id="contact-email"
                            name="contact-email"
                            label="Email"
                            placeholder="jondoe@email.com"
                            margin="normal"
                            variant="outlined"
                            required={true}
                            fullWidth
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                        />
                        <TextField
                            id="contact-message"
                            label="Message"
                            placeholder="..."
                            multiline
                            rows="4"
                            rowsMax="4"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            required={true}
                            value={this.state.message}
                            onChange={this.handleMessageChange}
                        />
                        <Button variant="outlined" color="default" size="large"
                                onClick={() => {
                                    this.handleSubmit();
                                }}>
                            SEND
                        </Button>
                        <br/>
                        <Typography component="h6" variant="h6" style={{display: this.state.displaySuccessMessage}}>
                            Thank you, we will reply soon!
                        </Typography>
                    </form>
                </section>
            </div>
        );
    }
}

export default withStyles(styles)(Contact);
