class Navigator {
    static navigateToAbout() {
        window.location.href = '#about';
    }

    static navigateToContact() {
        window.location.href = '#contact';
    }

    static navigateToSubscribe() {
        window.location.href = '#subscribe';
    }

    static navigateToBetaRegister() {
        window.open("https://beta-app.appsmith.io/identity/Account/Register", "_blank");
    }
}

export default Navigator;
