import React from 'react';
import logo from "./../logo.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/es/Button/Button";
import {withStyles} from '@material-ui/core/styles';
import Navigator from './../util/Navigator';
import background from "./../assets/appsmith-header-background.svg";
import Copyright from "./Copyright";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }
});

const headerStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundOrigin: 'content-box'
};

class Header extends React.Component {
    render() {
        return (
            <header className="App-header" style={headerStyle}>
                <img src={logo} className="App-logo" alt="logo"/>
                <br/>
                <Typography component="h1" variant="h2" align="center" color="textPrimary">
                    App Smith
                </Typography>
                <br/>
                <Typography component="h5" variant="h5" align="center" color="textSecondary">
                    fast and easy scaffolding for your ideas
                </Typography>
                <br/>
                <Copyright/>
                <br/>
                <div>
                    <Button variant="outlined" color="default" size="large"
                            onClick={() => {
                                Navigator.navigateToAbout();
                            }}>
                        ABOUT
                    </Button>
                    <span>&nbsp;</span>
                    <Button variant="outlined" color="default" size="large"
                            onClick={() => {
                                Navigator.navigateToBetaRegister()
                            }}>
                        REGISTER FOR BETA
                    </Button>
                    <span>&nbsp;</span>
                    <Button variant="outlined" color="default" size="large"
                            onClick={() => {
                                Navigator.navigateToContact()
                            }}>
                        CONTACT
                    </Button>
                </div>
            </header>
        );
    }
}

export default withStyles(styles)(Header);
