import axios from 'axios';

const subscribeEndpoint = 'http://api.appsmith.io/api/subscriptions';

export default class SubscribeApi {
    static subscribe(name, email) {
        return axios.post(subscribeEndpoint, {
            name: name,
            email: email
        });
    }
}
