import React from 'react';
import Typography from '@material-ui/core/Typography';

class Copyright extends React.Component {
    render() {
        return (
            <Typography>
                &copy;{new Date().getFullYear()} AppSmith
            </Typography>
        );
    }
}

export default (Copyright);
