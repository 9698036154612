import React from 'react';
import Copyright from "./Copyright";

class Footer extends React.Component {
    render() {
        return (
            <div id="footer" className="App-footer">
                <footer>
                    <Copyright/>
                </footer>
            </div>
        )
    }
}

export default (Footer);
