import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/es/Button';
import SubscribeApi from "../util/SubscribeApi";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    }
});

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email: '', displaySuccessMessage: 'none'};
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit() {
        if (!this.state.name || !this.state.email) {
            return;
        }
        const handler = () => {
            this.setState({name: '', email: '', displaySuccessMessage: 'block'});
            setTimeout(() => {
                this.setState({displaySuccessMessage: 'none'});
            }, 3000);
        };
        SubscribeApi.subscribe(this.state.name, this.state.email)
            .then(() => handler())
            .catch(reason => {
                handler();
                console.error(reason);
            });
    }

    render() {
        return (
            <div id="subscribe" className="App-subscribe">
                <section>
                    <Typography component="h3" variant="h3" align="center" color="textPrimary">
                        SUBSCRIBE
                    </Typography>
                    <br/>
                    <Typography component="h5" variant="h5" align="center" color="textSecondary">
                        we'll keep you posted with updates regarding the launch
                    </Typography>
                    <br/>
                    <form autoComplete="off">
                        <TextField
                            id="subscribe-name"
                            name="subscribe-name"
                            label="Name"
                            placeholder="Jon Doe"
                            margin="normal"
                            variant="outlined"
                            required={true}
                            fullWidth
                            value={this.state.name}
                            onChange={this.handleNameChange}
                        />
                        <TextField
                            type="email"
                            id="subscribe-email"
                            name="subscribe-email"
                            label="Email"
                            placeholder="jondoe@email.com"
                            margin="normal"
                            variant="outlined"
                            required={true}
                            fullWidth
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                        />
                        <Button variant="outlined" color="default" size="large"
                                onClick={() => {
                                    this.handleSubmit();
                                }}>
                            SUBMIT
                        </Button>
                        <br/>
                        <Typography component="h6" variant="h6" style={{display: this.state.displaySuccessMessage}}>
                            Thank you for subscribing!
                        </Typography>
                    </form>
                </section>
            </div>
        );
    }
}

export default withStyles(styles)(Subscribe);
